<template>
  <div class="home_page">
    <div class="info">
      <img src="../../assets/image/huiyuankaitong.png" />
    </div>
    <div class="b-btn">
      <div class="l">价格￥{{ price }}</div>
      <div class="r" @click="goBuy">
        立即购买
      </div>
      <!-- <van-submit-bar :price="3050" button-text="提交订单" @submit="onSubmit" /> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      price: '',
      id: '',
    };
  },
  mounted() {
    // this.getPrice();
  },
  methods: {
    // 立即购买
    async getPrice() {
      await cardPrice().then((res) => {
        console.log(res);
        this.price = res.data;
      });
    },
    //
    async goBuy() {
      await createLifeOrder({
        price: this.price,
        userId: this.id,
        payType: 'zfb',
      }).then((res) => {
        console.log(res);
        this.toPay(res.data);
      });
    },
    async toPay(orderSn) {
      let that = this;
      let platform = uni.getSystemInfoSync().platform;
      let type = '';
      if (platform == 'android') {
        type = 'A';
      }
      if (platform == 'ios') {
        type = 'I';
      }
      let res = await zfbPay({
        clientType: type,
        goodsType: 1,
        out_trade_no: orderSn,
      });
      uni.requestPayment({
        provider: 'alipay',
        orderInfo: res.data,
        success(res) {
          console.log(res);
          uni.showToast({
            title: '支付成功!',
            success() {
              uni.redirectTo({
                url: '/pages/my/myServe',
              });
            },
          });
        },
        fail(res) {
          console.log(res);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home_page {
  padding-bottom: 60px;
  background: #fff;
}

.tt {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  text-align: center;
  margin-bottom: 30px;
  position: relative;

  .bind {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 24px;
    color: #333;
  }

  img {
    width: 100%;
  }
}

.info {
  position: relative;
  padding: 0 30px;

  .price {
    position: absolute;
    top: 200px;
    left: 280px;
    display: flex;
    align-items: flex-end;

    .l {
      font-weight: bold;
      // color: $theme-color;
      font-size: 42px;
    }

    .r {
      margin-left: 20px;
      color: #8e8e8e;
      text-decoration: line-through;
    }
  }

  img {
    width: 100%;
  }
}

.b-btn {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 7px  20px;
  font-size: 16px;
  width: calc(100% - 0px);
  border-top: 1px solid rgba(233, 48, 45, 0.2);

  .l {
    text-align: left;
    width: 50%;
  }

  .r {
    width: 50%;
    background-color: rgb(233, 48, 45);
    border-color: rgb(233, 48, 45);
    color: #fff;
    transition: all 2s ease;
    height: 40px;
    padding: 0 15px !important;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
